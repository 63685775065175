




























































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































.ovy-a {
  display: flex;
  position: relative;

  .tacticsRule_left {
    width: 150px;

    .shell {
      position: fixed;
      line-height: 30px;
      overflow-y: scroll;
      height: 81%;
      width: 150px;

      .shell_1 {
        font-weight: 600;
        font-size: 16px;
        padding-left: 20px;
      }

      .shell_2 {
        padding-left: 24px;

        &:hover {
          cursor: pointer;
          color: #409EFF;
        }
      }

      .shell_3 {
        padding-left: 4px;
      }

      .shell_3 {
        padding-left: 0px;
        color: #8a8a8a;
      }
    }
  }

  .tacticsRule_right {
    flex: 1;
    padding-left: 20px;
    width: 80%;

    // overflow-x: scroll;
    .el-form {
      width: 500px;

      .jbxx {
        /deep/ .el-form-item__label {
          font-size: 16px;
          font-weight: 600;
        }

      }
    }

    .tacticsRule_right_list {
      margin-bottom: 20px;

      .listTop {
        display: flex;
        justify-content: space-between;
        line-height: 30px;
        background: #f6f6f6;
        padding: 4px 0 4px 10px;

        .listTop_left_1 {
          font-size: 16px;
          font-weight: 600;
        }

        .listTop_left_2 {
          font-size: 18px;
          padding: 0 20px 0 10px;

          &:hover {
            cursor: pointer;
            color: #409EFF;
          }
        }

        .listTop_left_3 {
          color: #409EFF;
        }
      }

      .listBottom {
        padding-top: 10px;
        text-align: center;
      }
    }
  }

  .operateDiv {
    position: absolute;
    top: 0;
    right: 20px;
  }
}

/deep/ .el-table__fixed-right,
/deep/ .el-table .el-table__fixed {
  height: 100% !important;
}
