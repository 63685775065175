.ovy-a {
  display: flex;
  position: relative;
}
.ovy-a .tacticsRule_left {
  width: 150px;
}
.ovy-a .tacticsRule_left .shell {
  position: fixed;
  line-height: 30px;
  overflow-y: scroll;
  height: 81%;
  width: 150px;
}
.ovy-a .tacticsRule_left .shell .shell_1 {
  font-weight: 600;
  font-size: 16px;
  padding-left: 20px;
}
.ovy-a .tacticsRule_left .shell .shell_2 {
  padding-left: 24px;
}
.ovy-a .tacticsRule_left .shell .shell_2:hover {
  cursor: pointer;
  color: #409EFF;
}
.ovy-a .tacticsRule_left .shell .shell_3 {
  padding-left: 4px;
}
.ovy-a .tacticsRule_left .shell .shell_3 {
  padding-left: 0px;
  color: #8a8a8a;
}
.ovy-a .tacticsRule_right {
  flex: 1;
  padding-left: 20px;
  width: 80%;
}
.ovy-a .tacticsRule_right .el-form {
  width: 500px;
}
.ovy-a .tacticsRule_right .el-form .jbxx /deep/ .el-form-item__label {
  font-size: 16px;
  font-weight: 600;
}
.ovy-a .tacticsRule_right .tacticsRule_right_list {
  margin-bottom: 20px;
}
.ovy-a .tacticsRule_right .tacticsRule_right_list .listTop {
  display: flex;
  justify-content: space-between;
  line-height: 30px;
  background: #f6f6f6;
  padding: 4px 0 4px 10px;
}
.ovy-a .tacticsRule_right .tacticsRule_right_list .listTop .listTop_left_1 {
  font-size: 16px;
  font-weight: 600;
}
.ovy-a .tacticsRule_right .tacticsRule_right_list .listTop .listTop_left_2 {
  font-size: 18px;
  padding: 0 20px 0 10px;
}
.ovy-a .tacticsRule_right .tacticsRule_right_list .listTop .listTop_left_2:hover {
  cursor: pointer;
  color: #409EFF;
}
.ovy-a .tacticsRule_right .tacticsRule_right_list .listTop .listTop_left_3 {
  color: #409EFF;
}
.ovy-a .tacticsRule_right .tacticsRule_right_list .listBottom {
  padding-top: 10px;
  text-align: center;
}
.ovy-a .operateDiv {
  position: absolute;
  top: 0;
  right: 20px;
}
 /deep/ .el-table__fixed-right,
 /deep/ .el-table .el-table__fixed {
  height: 100% !important;
}
